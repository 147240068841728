import React, { useState, useEffect } from "react";
import useAuth from 'hooks/useAuth';
import axios from "utils/axios";
import ClientsMappingTable from "components/ClientMappingTable";
import ProceduresTable from "components/ClientProceduresTable";
import { Box, Stack } from "@mui/system";
import ProcedureForm from "components/ProcedureForm";
import { Button, CircularProgress, Tab, Tabs } from "@mui/material";
import { User } from "types";
import { useClientProcedures } from "services/queries";
import { TabContext, TabPanel } from "@mui/lab";
import { a11yProps } from "components/CustomTabs";

const ClientProcedures = () => {
    const [clients, setClients] = useState([]);
    const [clientId, setClientId] = useState<number | null>(null);
    const [selectedProc, setSelectedProc] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [create, setCreate] = useState(false);
    const [edit, setEdit] = useState(false);
    const { user }: { user: User } = useAuth();
    const procedures = useClientProcedures(clientId)
    const [tab, setTab] = useState(0)

    useEffect(() => {
        const init = async () => {
            let response = await axios.get("/v1/clients");
            setClients(response.data);
        };

        init();
    }, [refresh]);

    useEffect(() => {
        if (user?.ClientFilter) {
            setClientId(user.ClientFilter)
        }
    }, [user])

    if (!clientId) {
        return <ClientsMappingTable clients={clients} setClientId={setClientId} />
    } else if (create) {
        return <ProcedureForm procedureId={null} clientId={clientId} back={() => { setCreate(false); setRefresh(!refresh) }} />
    } else if (edit && selectedProc.length === 1) {
        return <ProcedureForm clientId={clientId} procedureId={selectedProc[0]} back={() => { setEdit(false); setRefresh(!refresh) }} />
    }

    if (procedures.isLoading) {
        return <CircularProgress />
    }

    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Button variant="contained" onClick={() => setCreate(true)}>Create</Button>
                <Button variant="outlined" color="secondary" onClick={() => setEdit(true)}>Edit</Button>
            </Stack>
            <Box height={`calc(100vh - 425px)`}>
                {/* @ts-ignore */}
                <TabContext value={tab}>
                    <Tabs
                        value={tab}
                        onChange={(_, v) => setTab(v)}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Panels" {...a11yProps(0)} />
                        <Tab label="Procedures" {...a11yProps(1)} />
                    </Tabs>
                    {/* @ts-ignore */}
                    <TabPanel value={0} index={0}>
                        <ProceduresTable selected={[]} rows={procedures.data.filter((p: any) => p.Explode)} setSelected={setSelectedProc} disableCheckboxes={true} clientId={clientId} />
                    </TabPanel>
                    {/* @ts-ignore */}
                    <TabPanel value={1} index={1}>
                        <ProceduresTable selected={[]} rows={procedures.data.filter((p: any) => !p.Explode)} setSelected={setSelectedProc} disableCheckboxes={true} clientId={clientId} />
                    </TabPanel>
                </TabContext>
            </Box>
        </>
    )


}

export default ClientProcedures;
