import React from 'react';
import { Checkbox, FormControlLabel, MenuItem, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";

function CustomSelectField({ name, label, required, options, ...props }) {
    const { handleBlur, setFieldValue, errors, touched, values } =
        useFormikContext();

    return (
        <TextField
            select
            required={required}
            name={name}
            label={label || name}
            helperText={touched?.[name] && errors?.[name]}
            error={touched?.[name] && Boolean(errors?.[name])}
            onBlur={handleBlur}
            onChange={(e) => setFieldValue(name, e.target.value)}
            value={values?.[name]}
            {...props}>
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
}

function getNestedProperty(obj, path) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}


function CustomTextField({ name, label, required, ...props }) {
    const { handleBlur, setFieldValue, errors, touched, values } =
        useFormikContext();
    const [field] = useField(name)

    return (
        <TextField
            required={required}
            name={name}
            label={label || name}
            helperText={touched?.[name] && errors?.[name]}
            error={touched?.[name] && Boolean(errors?.[name])}
            onBlur={handleBlur}
            onChange={(e) => setFieldValue(name, e.target.value)}
            value={getNestedProperty(values, name)}
            InputLabelProps={{
                shrink: !!field.value,
            }}

            {...props}
        />
    );
}

const CustomCheckboxField = ({ name, label, ...props }) => {
    const [field, _, helpers] = useField(name);
    return (
        <FormControlLabel
            control={
                <Checkbox
                    name={String(name)}
                    checked={Boolean(field.value)}
                    onChange={(_, value) => helpers.setValue(value)}
                    {...props}
                />
            }
            label={label || String(name)}
        />
    );
};



export { CustomSelectField, CustomCheckboxField, };
export default CustomTextField;
