// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import { LicenseInfo } from '@mui/x-data-grid-pro';

// auth-provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

LicenseInfo.setLicenseKey(
  "4f1c625e62d46a23c8f2580d053085d4Tz01OTE5NCxFPTE3MDcxNjE5MDc4NjMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const queryClient = new QueryClient()


const App = () => (
  <QueryClientProvider client={queryClient}>
    <ThemeCustomization>
      {/* <RTLLayout> */}
      <Locales>
        <ScrollTop>
          <AuthProvider>
            <>
              <Notistack>
                <Routes />
                <Snackbar />
              </Notistack>
            </>
          </AuthProvider>
        </ScrollTop>
      </Locales>
      {/* </RTLLayout> */}
    </ThemeCustomization>
  </QueryClientProvider>
);

export default App;
