import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createImplodeRule, createStageRule, deleteImplodeRule, deleteStageRule, getClientAccounts, getClients, getClientProcedures, getImplodeRule, getImplodeRules, getStageRule, getStageRules, updateImplodeRule, updateStageRule, getPayers, getClientProceduresAll, getClientProcedure } from "./api";

export function useImplodeRules(clientId?: number) {
	return useQuery({
		queryKey: ['implodeRules'],
		queryFn: () => getImplodeRules(),
		select: (data) => {
			if (!clientId) return data
			return data.filter((r: any) => r.ClientID === clientId)
		}
	})
}

export function useImplodeRule(id?: number) {
	return useQuery({
		queryKey: ['implodeRules', id],
		queryFn: () => getImplodeRule(id!),
		enabled: !!id,
	})
}

export function useDeleteImplodeRule() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteImplodeRule,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['implodeRules'] })
		}
	})
}

export function useCreateImplodeRule() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createImplodeRule,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['implodeRules'] })
		}
	})
}

export function useUpdateImplodeRule() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateImplodeRule,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['implodeRules'] })
		}
	})
}

export function useStageRules() {
	return useQuery({
		queryKey: ['stageRules'],
		queryFn: () => getStageRules()
	})
}

export function useStageRule(id?: number) {
	return useQuery({
		queryKey: ['stageRule', id],
		queryFn: () => getStageRule(id!),
		enabled: !!id,

	})
}

export function useCreateStageRule() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createStageRule,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['stageRules'] })
		}
	})
}

export function useUpdateStageRule() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateStageRule,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['stageRules'] })
		}
	})
}

export function useDeleteStageRule() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteStageRule,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['stageRules'] })
		}
	})
}

export function useClientProceduresAll() {
	return useQuery({
		queryKey: ['client_procedures'],
		queryFn: () => getClientProceduresAll(),
	})
}


export function useClientProcedures(clientId?: number | null) {
	return useQuery({
		queryKey: ['client_procedures', clientId],
		queryFn: () => getClientProcedures(clientId!),
		enabled: !!clientId,
	})
}

export function useClients() {
	return useQuery({
		queryKey: ['clients'],
		queryFn: () => getClients()
	})
}

export function useClientAccounts() {
	return useQuery({
		queryKey: ['client_accounts'],
		queryFn: () => getClientAccounts()
	})
}

export function useClientProcedure(clientId: number, procedureId: number) {
	return useQuery({
		queryKey: ['client_procedure', clientId, procedureId],
		queryFn: () => getClientProcedure(clientId, procedureId)
	})
}

export function usePayers() {
	return useQuery({
		queryKey: ['payers'],
		queryFn: () => getPayers()
	})
}
