const FinancialClass = {
    Medicare: 0,
    Medicaid: 1,
    Commercial: 2,
    SelfPay: 3
};

const FeeScheduleTypes = {
    SelfPay: 0,
    ClientBill: 1,
    Insurance: 2
}

const FeeScheduleNames = {
    0: "Self Pay",
    1: "Client Bill",
    2: "Insurance"
}

const ProcedureTypes = [
    "Tox",
    "Blood",
    "UTI PCR",
    "STI PCR",
    "GI PCR",
    "RPP PCR",
    "WH PCR",
    "WND PCR",
    "Nail PCR",
]


export default FinancialClass;
export { FeeScheduleTypes, FeeScheduleNames, ProcedureTypes };
