import { GridToolbar } from "@mui/x-data-grid-pro"
import React from "react";
import { useMemo } from "react";
import { StyledDataGrid } from "./DataTable";
import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import { useClientProcedure } from "services/queries";

const ProceduresTable = ({ rows, selected, setSelected, disableCheckboxes, clientId }) => {

    const columns = useMemo(() => [
        { field: "ID", headerName: "ID", width: 70 },
        { field: "Code", headerName: "Code", width: 95 },
        { field: "Name", headerName: "Name", width: 150 },
        { field: "CPTCode", headerName: "CPT Code", width: 100 },
        { field: "AMDCode", headerName: "AMD Code", width: 100 },
        { field: "Explode", headerName: "Explodes", width: 95 },
        { field: "CPTModifiers", headerName: "Modifiers", width: 150, valueGetter: (params) => params.row.CPTModifiers.map((modifier) => modifier.Code).join(", ") },
    ], []);

    const renderDetail = (params) => {
        if (!params.row.Explode || !clientId) {
            return null;
        }

        return <DetailPanelContent params={params} clientId={clientId} />;
    }


    return (
        <>
            <StyledDataGrid
                getRowId={(row) => row.ID}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "ID", sort: "asc" }],
                    },
                }}
                density="compact"
                sx={{
                    height: "1000px",
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                slots={{ toolbar: GridToolbar }}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                getDetailPanelContent={renderDetail}
                getDetailPanelHeight={() => "auto"} // Height based on the content.
                onRowSelectionModelChange={setSelected}
                rowSelectionModel={selected}
                pageSize={100}
                checkboxSelection={!disableCheckboxes}
                rows={rows}
                columns={columns}
            />
        </>
    );
};

function DetailPanelContent({ params, clientId }) {
    const procedure = useClientProcedure(clientId, params.row.ID)

    if (procedure.isLoading) {
        return <CircularProgress />
    }

    if (procedure.isError) {
        return <Alert severity="error">Failed to load procedure</Alert>
    }

    return (
        <Box p={2}>
            <Typography variant="h5">Subtests</Typography>
            <Typography variant="caption">Name - CPT Code</Typography>
            <ul>
                {procedure.data?.ExplodesTo.map((p) =>
                    <li>{p.Name} - {p.CPTCode}</li>)}
            </ul>
        </Box>
    );
}


export default ProceduresTable;
