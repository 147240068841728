import axios from "utils/axios";
import { Client, ClientAccount, StageRule } from "types";
import { ImplodeRule } from "constants/go_types";

export const getImplodeRules = async () => {
	return (await axios.get<ImplodeRule[]>('/v1/implodeRules')).data;
}

export const getImplodeRule = async (id: number) => {
	return (await axios.get<ImplodeRule>(`/v1/implodeRules/${id}`)).data;
}

export const createImplodeRule = async (m: ImplodeRule) => {
	return (await axios.post<ImplodeRule>('/v1/implodeRules', m)).data;
}

export const updateImplodeRule = async (m: ImplodeRule) => {
	return (await axios.put<ImplodeRule>('/v1/implodeRules', m)).data;
}

export const deleteImplodeRule = async (id: number) => {
	return (await axios.delete<ImplodeRule>(`/v1/implodeRules/${id}`)).data;
}

export const getStageRules = async () => {
	return (await axios.get<StageRule[]>('/v1/stageRules')).data;
}

export const getStageRule = async (id: number) => {
	return (await axios.get<StageRule>(`/v1/stageRules/${id}`)).data;
}

export const createStageRule = async (s: StageRule) => {
	return (await axios.post<StageRule>(`/v1/stageRules/`, s)).data;
}

export const deleteStageRule = async (id: number) => {
	return (await axios.delete<StageRule>(`/v1/stageRules/${id}`)).data;
}

export const updateStageRule = async (s: StageRule) => {
	return (await axios.post<StageRule>(`/v1/stageRules/`, s)).data;
}

export const getClientProceduresAll = async () => {
	return (await axios.get(`/v1/clients/procedures`)).data;
}

export const getClientProcedures = async (clientId: number) => {
	return (await axios.get(`/v1/clients/${clientId}/procedures`)).data;
}

export const getClients = async () => {
	return (await axios.get<Client[]>(`/v1/clients/`)).data;
}

export const getClientAccounts = async () => {
	return (await axios.get<ClientAccount[]>(`/v1/clients/accounts`)).data;
}

export const getPayers = async () => {
	return (await axios.get(`/v1/payers`)).data;
}

export const getClientProcedure = async (clientId: Number, procedureId: Number) => {
	return (await axios.get(`/v1/clients/${clientId}/procedures/${procedureId}`)).data;
}
