import React, { useRef, useState, useEffect, useLayoutEffect, useCallback } from 'react';
import { useDataGridState } from "../hooks/useDataGridState";
import { DataGridPro, GridToolbar, gridClasses } from "@mui/x-data-grid-pro";
import { styled, alpha } from '@mui/material/styles';

const ODD_OPACITY = 0.2;

function customCheckbox(theme) {
	return {
		'& .MuiCheckbox-root svg': {
			width: 16,
			height: 16,
			backgroundColor: 'transparent',
			border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
				}`,
			borderRadius: 2,
		},
		'& .MuiCheckbox-root svg path': {
			display: 'none',
		},
		'& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
			backgroundColor: '#1890ff',
			borderColor: '#1890ff',
		},
		'& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
			position: 'absolute',
			display: 'table',
			border: '2px solid #fff',
			borderTop: 0,
			borderLeft: 0,
			transform: 'rotate(45deg) translate(-50%,-50%)',
			opacity: 1,
			transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
			content: '""',
			top: '50%',
			left: '39%',
			width: 5.71428571,
			height: 9.14285714,
		},
		'& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
			width: 8,
			height: 8,
			backgroundColor: '#1890ff',
			transform: 'none',
			top: '39%',
			border: 0,
		},
	};
}

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
	[`& .${gridClasses.row}.even`]: {
		backgroundColor: theme.palette.grey[200],
		'&:hover, &.Mui-hovered': {
			backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
			'@media (hover: none)': {
				backgroundColor: 'transparent',
			},
		},
		'&.Mui-selected': {
			backgroundColor: alpha(
				theme.palette.primary.main,
				ODD_OPACITY + theme.palette.action.selectedOpacity,
			),
			'&:hover, &.Mui-hovered': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					ODD_OPACITY +
					theme.palette.action.selectedOpacity +
					theme.palette.action.hoverOpacity,
				),
				// Reset on touch devices, it doesn't add specificity
				'@media (hover: none)': {
					backgroundColor: alpha(
						theme.palette.primary.main,
						ODD_OPACITY + theme.palette.action.selectedOpacity,
					),
				},
			},
		},
	},
	// ...customCheckbox(theme),
}));


function DataTable({ jsonKey, columns, data, setSelected, selected, ...props }) {
	if (!jsonKey) {
		jsonKey = "datagrid"
	}
	const defaultValue = {};
	console.log(jsonKey)

	const apiRef = useRef();

	const [initialState, setInitialState] = useState(() => {
		const stateFromLocalStorage = localStorage.getItem(jsonKey);
		return stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : defaultValue;
	});

	const saveSnapshot = useCallback(() => {
		if (apiRef?.current?.exportState && localStorage) {
			const currentState = apiRef.current.exportState();
			console.log(`exporting state for ${jsonKey}`, currentState)
			localStorage.setItem(jsonKey, JSON.stringify(currentState));
		}
	}, [apiRef]);

	useLayoutEffect(() => {
		const stateFromLocalStorage = localStorage?.getItem(jsonKey);
		setInitialState(stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {
			sorting: {
				sortModel: [{ field: "ID", sort: "asc" }],
			},
			pagination: {
				paginationModel: { pageSize: 50, page: 0 },
			},
		});

		// handle refresh and navigating away/refreshing
		window.addEventListener('beforeunload', saveSnapshot);

		return () => {
			// in case of an SPA remove the event-listener
			window.removeEventListener('beforeunload', saveSnapshot);
			saveSnapshot();
		};
	}, [saveSnapshot]);



	return (
		<StyledDataGrid
			apiRef={apiRef}
			getRowId={(row) => row.ID}
			keepNonExistentRowsSelected
			initialState={{
				sorting: {
					sortModel: [{ field: "ID", sort: "asc" }],
				},
				...initialState,
			}}
			density="compact"
			getRowClassName={(params) =>
				params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
			}
			slots={{ toolbar: GridToolbar }}
			slotProps={{
				toolbar: {
					showQuickFilter: true,
					quickFilterProps: { debounceMs: 500 },
				},
			}}
			onRowSelectionModelChange={setSelected}
			rowSelectionModel={selected}
			pageSize={100}
			checkboxSelection={true}
			rows={data || []}
			columns={columns}
			{...props}
		/>
	)
}

export default DataTable;
